import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const StandardProduct = () => {
	const { t } = useTranslation();
	const settings = {
		dots: true,
		infinite: true,
		autoplaySpeed: 4000,
		autoplay: true,
		slidesToShow: window.outerWidth < 700 ? 1 : 2,
		slidesToScroll: 1
	};
	return <section className="o-section o-section--waveup o-section--wavebreak o-section--white o-section__animation">
		{/* <div className='animation_song'>
			<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
				viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
				<defs>
					<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
				</defs>
				<g className="parallax">
					<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
					<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
					<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
					<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
				</g>
			</svg>
		</div> */}
		<div className="o-section__container">
			<div className="o-section__wrapper">
				<section className="o-product-section">
					<h2 className="a-heading a-heading--dropblue o-product-section__heading">{t('standard.STANDARDPRODUCTS')}</h2>
					<div className="o-product-section__content">
						<p className="a-text o-product-section__description-sp">{t('standard.MQTsstandard')}</p>
						<div className='m-product-slider'>
							<div className='m-product-slider__list-wrapper'>
								<div className="m-product-slider__snapper">
									<a className="m-product-slider__prev">
										<i className="a-icon a-icon--arrow-left"></i>
									</a>
									<a className="m-product-slider__next">
										<i className="a-icon a-icon--arrow-right"></i>
									</a>
								</div>
							</div>
							<Slider {...settings}>
								<img className="slideimg" src="/assets/images/ro/1.jpg" />
								<img className="slideimg" src="/assets/images/ro/2.jpg" />
								<img className="slideimg" src="/assets/images/ro/4.jpg" />
								<img className="slideimg" src="/assets/images/ro/5.jpg" />
								<img className="slideimg" src="/assets/images/ro/6.jpg" />
							</Slider>
							<ul className="m-product-slider__pagination">
								<li className="m-product-slider__dots m-product-slider__dots--active"></li>
								<li className="m-product-slider__dots"></li>
								<li className="m-product-slider__dots"></li>
								<li className="m-product-slider__dots"></li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</div>
	</section>
}

export default StandardProduct