import React from 'react';
import { useTranslation } from 'react-i18next';


const BusinessField = () => {
  const { t } = useTranslation();

  return <section className="o-section o-section--blue-gradient o-section__animation reveal">
    <div className="o-section__container">
      <div className="o-section__wrapper">
        <h2 className="a-heading a-heading--dropwhite">{t('product.businessfield')}</h2>
        <article className="m-article m-article--reverse mt-5">
          <div className="m-article__heading">
            <h5 style={{color:'#238bcd'}}>{t('product.ElectronicsIndustry')}</h5>
            <i className="a-icon a-icon--plus m-article__toggle-icon"></i>
            <div className="m-article__description-pc">
              <p className="a-text">{t('product.MQTisaworld')}
                <br className="a-br a-br--only-pc" /> {t('product.Ultrapurewater')}</p>
              <p className="a-text">{t('product.Asawater')}
                <br className="a-br a-br--only-pc" />{t('product.MQTcanprovide')}
                <br className="a-br a-br--only-pc" />{t('product.MQTcansupport')}</p>
            </div>
          </div>
          <div className="m-article__content-wrapper">
            <div className="m-article__content">
              <div className="lazyload-wrapper ">
                <picture>
                  <img className="a-image a-image--curvedown mt-3" src="/assets/images/dringwarter.jpg" alt="Electronics Industry" />
                </picture>
              </div>
              <div className="m-article__description-sp">
                <p >{t('product.MQTisaworld')}
                  <br className="a-br a-br--only-pc" /> {t('product.Ultrapurewater')}</p>
                <p >{t('product.Asawater')}
                  <br className="a-br a-br--only-pc" />{t('product.MQTcanprovide')}</p>
              </div>
            </div>
          </div>
        </article>
        <article className="m-article mt-5">
          <div className="m-article__heading">
            <h5 style={{color:'#238bcd'}}>{t('product.Pharmaceuticals')}</h5>
            <i className="a-icon a-icon--plus m-article__toggle-icon"></i>
            <div className="m-article__description-pc">
              <p >{t('product.Utilizingtopdelivery')}</p>
              <p >{t('product.Becauseoftherapid')}
                <br className="a-br a-br--only-pc" />{t('product.MQTcansupport')}</p>
            </div>
          </div>
          <div className="m-article__content-wrapper">
            <div className="m-article__content">
              <div className="lazyload-wrapper ">
                <picture>
                  <img className="a-image a-image--curvedown mt-3" src="/assets/images/ro/6.jpg" alt="Pharmaceuticals / Cosmetics /Medical Devices" />
                </picture>
              </div>
              {/* <div className="m-article__description-sp">
                <p className="a-text">{t('product.Utilizingtopdelivery')}</p>
                <p className="a-text">{t('product.Becauseoftherapid')}
                  <br className="a-br a-br--only-pc" /> {t('product.MQTcansupport')}</p>
              </div> */}
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
}


export default BusinessField