import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';
import StandardProduct from './standardproduct';
import BusinessField from './BusinessField';
import { useTranslation } from 'react-i18next';

const Product = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle={t('narbar.product')}  />
        <StandardProduct />
        <BusinessField />
        <Footer />
    </div>
}

export default Product

