import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactInfomation = () => {
  const { t } = useTranslation();

  return <section className="o-section o-section--waveup o-section--wavebreak o-section--white o-section__animation">
    {/* <div className='animation_song'>
      <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div> */}
    <div className="o-section__container ">
			<div className="o-section__wrapper">
				<section className="o-contact-info-section">
					<h2 className="a-heading a-heading--dropblue">{t('contactus.contactinfo')}</h2>
					<div className="o-contact-info-section__content">
						<div className="o-contact-info-section__col">
							<div className="a-map">
								<iframe className="a-map__iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31341.84500619742!2d106.70596174962132!3d10.908064943150363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d829df7f914f%3A0xd1a1920c5b4d96fd!2zQsOsbmggSMOyYSwgVGh14bqtbiBBbiwgQsOsbmggRMawxqFuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1663137349650!5m2!1svi!2s" width="100%" height="100%" shapeRendering="" loading="lazy" style={{ border: '0px' }}></iframe>
							</div>
							<div className="m-contact-address">
								<dl className="m-contact-address__info" style={{display: 'grid',gridAutoFlow: 'column'}}>
									<div className="footer-widget__contact-box">
										<div className="footer-widget__contact-top">
											<i className="far fa-map-marked" />
											<h3>Address</h3>
										</div>
										<p>383 KP Đông Ba - P. Bình Hòa - TP Thuận An - T Bình Dương</p>
									</div>
									<div >
										<div className="footer-widget__contact-top">
											<i className="far fa-phone" />
											<h3>Phone</h3>
										</div>
										<p><a href="tel:0987575507">0987575507</a></p>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
  </section>

}

export default ContactInfomation