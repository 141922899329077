import React from 'react';
import Corporationabout from './corporationabout';
import History from './history';
import WorldwideNetwork from './worldwideNetwork';
import Footer from '../global-components/footer';
import Navbar from '../global-components/navbar';
import Page_header from '../global-components/page-header';
import Overview from './overview';
import { useTranslation } from 'react-i18next';
import StandardProduct from '../product/standardproduct';
import BusinessField from '../product/BusinessField';
const AboutPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <Page_header headertitle={'Về MQT'}  />
        <Overview />
        <WorldwideNetwork />
        <Corporationabout />
        {/* <History /> */}
        {/* <StandardProduct />
        <BusinessField /> */}
        <Footer />
    </div>
}

export default AboutPage

