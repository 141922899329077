import React from 'react';
import { useTranslation } from 'react-i18next';


const OurProfile = () => {
    const { t } = useTranslation();

    return <section className="service-one go-top o-section">
       <div className='animation_song'>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </div>
        <div className="o-section__container home_animation">
            <div className="o-section__wrappered">
                <div className="col m-paragraph">
                    <h2 className="a-heading a-heading--dropblue mb-3">{t('home.ourprofile')}</h2>
                    <p className="a-text">{t('home.infoourprofile')}</p>
                    <p className="a-text">{t('home.infoourprofile2')}</p>
                </div>
                <div className="p-home__profile-image col" style={{alignSelf: 'center'}}>
							<img className="a-images logomainsale image-home" src='/assets/images/ro/1.jpg' alt="main sale items" />
						</div>

            </div>
        </div>
    </section>

}

export default OurProfile