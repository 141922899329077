import React from "react";
import { useTranslation } from "react-i18next";

const WorldwideNetwork = () => {
  const { t } = useTranslation();
  return (
    <div className="row dblock o-section__wrapper" style={{marginTop:'170px', marginBottom:'100px'}}>
        <div className="col" style={{textAlign:'center'}}>
          <img
            src="/assets/images/icon_vision.png"
            alt=""
            style={{ width: "20%", margin:'20px' }}
          />
          <h5 className="h3-why">{t("overview.h3_1")}</h5>
          <p>{t("overview.span1")}</p>
        </div>
        <div className="col"  style={{textAlign:'center'}}>
          <img
            src="/assets/images/icon_mission.png"
            alt=""
            style={{ width: "20%", margin:'20px' }}
          />
          <h5 className="h3-why">{t("overview.h3_2")}</h5>
          <p>{t("overview.span2")}</p>
        </div>
        <div className="col"  style={{textAlign:'center'}}>
          <img
            src="/assets/images/icon_value.png"
            alt=""
            style={{ width: "20%", margin:'20px' }}
          />
          <h5 className="h3-why">{t("overview.h3_3")}</h5>
          <p>{t("overview.span3")}</p>
        </div>
      </div>
  );
};

export default WorldwideNetwork;
