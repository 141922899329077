import React from 'react';
import { useTranslation } from 'react-i18next';

const Body = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="page-header" >
                <div className="container" style={{ textAlign: 'left' }}>
                    
                </div>{/* /.container */}
            </section>
            <section className="o-section p-not-found__content o-section__animation">
                <div className="o-section__container" style={{ height: '300px' }}>
                    <div className="o-section__wrapper" style={{textAlign:'center'}}>
                        <h2 className="a-heading a-heading--dropblue p-not-found__heading">{t('404.NOTFOUND')}</h2>
                        <p className="a-text">{t('404.nottheweb')}</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Body

