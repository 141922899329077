import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };
  const mainVideo =publicUrl + 'video/ocean.mp4';
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  return (
    <section className="banner-one go-top bg-video-wrap">
      {
        shouldUseImage ? (
          <img src={mainVideo} alt="Muted Video" />
        ) : (
          <div
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                preload="metadata"
              >
              <source src="${mainVideo}" type="video/mp4" />
              </video>`
            }}
          />
        )
      }
      {/* <video
        src={process.env.PUBLIC_URL + "/video/ocean.mp4"}
        autoPlay
        loop
        muted
        playsInline
        style={{ filter: "blur(2px)" }}
      ></video> */}
      {/* <img src={publicUrl + "assets/images/imagesbanner.png"} alt={imagealt} className="banner_tay" /> */}
      <div className="background-brull"></div>
      <div className="water_border">
        <img
          src={publicUrl + "assets/images/backgrounds/banner-img.png"}
          alt={imagealt}
          className="globe_css"
        />
      </div>
      <div className="title_banner">
        <div className="o-keyvisual-top__content">
          <h2
            style={{
              fontFamily: "var(--LexendDecaSemiBold)",
              fontSize: "calc(7rem / 3)"
            }}
          >
            {t("home.titelhome")}
          </h2>
          {/* <h3 className="o-keyvisual-top__title mt-2">
					{t('home.headerhome')}
				</h3> */}
          <div className="o-keyvisual-top__description-wrap mt-4">
            <p className="a-text o-keyvisual-top__description">
              {t("home.infobannerhome")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
