import React from 'react';
import { useTranslation } from 'react-i18next';

const Overview = () => {
	const { t } = useTranslation();

	return <section className="o-section o-section--waveup o-section--wavebreak o-section--white o-section--no-padding p-about__overview-section o-section__animation mt-2">
		{/* <div className='animation_song'>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </div> */}
		<div className="o-section__container ">
			<div className="o-section__wrapper">
				<section className="o-about-overview-section">
					<div className="o-about-overview-section__wrapper mt-5">
						<h2 className="a-heading a-heading--dropblue">{t('about.overview')}</h2>
						<div className="o-about-overview-section__content">
							<figure className="o-about-overview-section__figure">
								<picture>
									<img className="o-about-overview-section__image" src="/assets/images/imgOverview.webp" alt="about overview" />
								</picture>
								{/* <figcaption className="o-about-overview-section__caption " > {t('about.infoOverview')} </figcaption> */}
							</figure>
							<div className="o-about-overview-section__detail">
								<h3  style={{color:'#238bcd'}}>{t('about.titelOverview')}</h3>
								<ul className="o-about-overview-section__list" style={{ padding: '0' }}>
									<li className="row">
										<h6 className="col-5 font-family">{t('about.nameCompany')}</h6>
										<p className=" col-7">{t('about.companyOverview')}</p></li>
									<li className="row mt-3">
										<h6 className="col-5 font-family">{t('about.establis')}</h6>
										<p className="col-7">2019</p>
									</li>
									<li className="row mt-3">
										<h6 className="col-5 font-family">{t('about.aboutLocation')}</h6>
										<p className="col-7">{t('about.address')}</p>
									</li>
									{/* <li className="row mt-3">
										<h6 className="col font-family">{t('about.numberEmployees')}</h6>
										<p className="col">{t('about.numberOverview')}</p>
									</li> */}
									<li className="row mt-3">
										<h6 className="col-5 font-family">{t('about.phone')}</h6>
										<p className="col-7">0977.870.664 (Mr. Cường)
										</p>
									</li>
									<li className="row mt-3">
										<h6 className="col-5 font-family"></h6>
										<p className="col-7">0902.408.665 (Mr. Vương)
										</p>
									</li>
								</ul>
								{/* <a href="/static/OrganoVN_Brochure_2017-c70113c0c79db49af55053bb707ad4a7.pdf" className="a-button-download" download=""> */}
								{/* <a href="" className="a-button-download" >
									<i className="a-icon a-icon--download a-button-download__icon"></i>{t('about.downloadSource')}</a> */}
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</section>
}


export default Overview