import React from 'react';
import Banner from '../global-components/banner';
import OurProfile from './ourprofile';
import Concept from './concrept';
import Contacus from './contactus';
import Mainsaleitems from './mainsaleitems';
import Footer_v1 from '../global-components/footer';
import Navbar from '../global-components/navbar';
import Water from './water';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <OurProfile />
        <Concept />
        <Mainsaleitems />
        <Contacus />
        <Water />
        <Footer_v1 />
    </div>
}

export default Home_V1

