import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';
import CompanyOverview from './companyoverview';
import { useTranslation } from 'react-i18next';

const Recruitment = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle={t('narbar.recruitment')}  />
        <CompanyOverview />
        <Footer />
    </div>
}

export default Recruitment

