import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Corporationabout = () => {
  const { t } = useTranslation();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  return (
    <section className="o-about-info-section mt-5 reveal">
      {/* <section className="o-section o-section--no-padding o-section__animation ">
			<div className="o-section__container mt-5">
				<div className="o-section__wrappered">
					<h4 className="o-about-info-section__title">{t('about.aboutCorporatoin')}</h4>
				</div>
			</div>
		</section>
		<section className="o-section o-section--no-padding o-about-info-section__content-section o-section__animation">
			<div className="o-section__wrappered" style={{position: 'inherit'}}>
				<div className="o-about-info-section__image">
					<div>
						<picture>
							<img className="a-image" src="/assets/images/imageMQT/corporation.jpg" alt="ORGANO CORPORATION" />
						</picture>
					</div>
				</div>
				<div className="o-about-info-section__content">
					<ul className="m-data-table" style={{ padding: '0' }}>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.nameCompany')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.aboutCorporatoin')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.aboutLocation')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.address')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.founded')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.day')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.capital')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.value')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.phone')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.phoneNumber')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.accountCloseDay')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.valueCloseDay')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.numberEmployees')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.valueEmployees')}</p>
								</div>
							</div>
						</li>
						<li className="m-data-table__item">
							<div className="m-data-row">
								<h4 className="m-data-row__heading">{t('about.branches')}</h4>
								<div className="m-data-row__content">
									<p className="a-text">{t('about.addressBraches')}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section> */}
      <div className="o-section__container">
        <div className="o-section__wrapper mt-5">
		  <h2 className="a-heading a-heading--dropblue ">TẠI SAO LẠI CHỌN DỊCH VỤ CỦA MQT</h2>
          <div style={{ margin: "auto" }} className="mt-5">
            <div className="accordion-item" onClick={() => setShow1(!show1)}>
              <span className="alige-text">
                ĐỘI NGŨ NHÂN VIÊN CHUYÊN NGHIỆP {show1 ?<img src="assets/images/up.png" alt="" className="icon-s" /> : <img src="assets/images/down.png" alt="" className="icon-s" />}
              </span>
            </div>
            <div className="accordion-inner">
              {show1 && (
                <p className="animationss">
                  Chúng tôi với đội ngũ nhân viên có nhiều năm trong nghề, được
                  đào tạo và huấn luyện bài bản,cùng với nhiều kinh nghiệm thực
                  tế và bản thân luôn thân hiện,trung thực luôn đáp ứng và làm
                  tốt mọi công việc được giao.
                </p>
              )}
            </div>
            <div className="accordion-item" onClick={() => setShow2(!show2)}>
              <span className="alige-text">
                QUY TRÌNH LÀM VIỆC CHUYÊN NGHIỆP {show2 ?<img src="assets/images/up.png" alt="" className="icon-s" /> : <img src="assets/images/down.png" alt="" className="icon-s" />}
              </span>
            </div>
            <div className="accordion-inner">
              {show2 && (
                <p>
                  Quy trình kiểm tra và giám sát được tổ chức thường xuyên và
                  chặt chẽ nhằm đảm bảo chất lượng dịch vụ.
                </p>
              )}
            </div>
            <div className="accordion-item" onClick={() => setShow3(!show3)}>
              <span className="alige-text">
				CHÍNH SÁCH GIÁ CẢ HỢP LÝ {show3 ?<img src="assets/images/up.png" alt="" className="icon-s" /> : <img src="assets/images/down.png" alt="" className="icon-s" />}
			  </span>
            </div>
            <div className="accordion-inner">
              {show3 && (
                <p>
                  Dù bạn thuộc nhóm doanh nghiệp nào, với quy mô, hình thức, và
                  bất kỳ thành phần kinh tế nào. Chúng tôi luôn cam kết đem lại
                  hiệu quả tốt nhất với chi phí hợp lý nhất. 
                </p>
              )}
            </div>
            <div className="accordion-item" onClick={() => setShow4(!show4)}>
              <span className="alige-text">
				TRANG THIẾT BỊ HIỆN ĐẠI {show4 ?<img src="assets/images/up.png" alt="" className="icon-s" /> : <img src="assets/images/down.png" alt="" className="icon-s" />}
				</span>
            </div>
            <div className="accordion-inner">
              {show4 && (
                <p>
                  Trang thiết bị máy móc, công cụ được đầu tư kỹ lưỡng về cả
                  chất lượng và số lượng do đó luôn luôn sẵn sàng đáp ứng tốt
                  tiến độ.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Corporationabout;
