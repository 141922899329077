import React from 'react';
import { useTranslation } from 'react-i18next';

const Concept = () => {
    const { t } = useTranslation();

    return <section className="o-section o-section--wavedown o-section--blue-stex o-section__animation" style={{paddingTop:'60px'}}>
        <div className="o-section__container" >
            <div className="o-section__wrapper concrep_margin reveal">
                <div>
                    <h2 className="a-heading a-heading--dropblue" style={{width:'100%'}}>{t('home.concept')}</h2>
                    {/* <p className="a-text">{t('home.infoConcept')}</p> */}
                    {/*<a className="a-view-more" href="/abouts">{t('home.viewmore')}<i className="a-icon--view-more a-view-more__icon"></i></a> */}
                    <div className='row dblock' style={{paddingTop:'50px'}}>
                        <div className='col h3-why'>
                            <img src='/assets/images/imageHome.webp' alt="" style={{width:'100%'}} />
                            <h5 className='h3-why'>{t('why.h3_1')}</h5>
                            <p className='p-why'>{t('why.span1')}</p>
                        </div>
                        <div className='col h3-why'>
                            <img src='/assets/images/imagewhy.webp' alt="" style={{width:'100%'}} />
                            <h5 className='h3-why'>{t('why.h3_2')}</h5>
                            <p className='p-why'>{t('why.span2')}</p>
                        </div>
                        <div className='col h3-why'>
                            <img src='/assets/images/whyimge.webp' alt="" style={{width:'100%'}} />
                            <h5 className='h3-why'>{t('why.h3_3')}</h5>
                            <p className='p-why'>{t('why.span3')}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </section>

}


export default Concept