import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyOverview = () => {
	const { t } = useTranslation();

	return <section className="o-recruit-article-section">
		<div className='animation_song'>
			<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
				viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
				<defs>
					<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
				</defs>
				<g className="parallax">
					<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
					<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
					<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
					<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
				</g>
			</svg>
		</div>
		<div className="o-recruit-article-section__content ">
			<div className="m-paragraph o-recruit-article-section__paragraph ">
				<h2 className="a-heading a-heading--dropblue">{t('CompanyOverview.OVERVIEW')}</h2>
				<div className="o-recruit-article-section__only-sp">
					<section className="o-section o-section--wavedown o-section__animation">
						<div className="o-section__container">
							<div className="o-section__wrapper">
							</div>
						</div>
					</section>
					<div className=" m-media--image">
						<div className='animation_song' style={{ transform: 'rotate(180deg)', marginTop: '-25px', position: 'absolute', width: '100%' }}>
							<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
								viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
								<defs>
									<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
								</defs>
								<g className="parallax">
									<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
									<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
									<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
									<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
								</g>
							</svg>
						</div>
						<div className="m-media__video-content">
							<picture>
								<img className="a-image" src="/assets/images/imageMQT/overlay.jpg" />
							</picture>
						</div>
						<div className='animation_song' style={{ marginTop: '-35px', position: 'absolute', width: '100%' }}>
							<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
								viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
								<defs>
									<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
								</defs>
								<g className="parallax">
									<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
									<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
									<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
									<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
								</g>
							</svg>
						</div>
						<div className="m-media__content">
							<i className="a-icon a-icon--play m-media__icon"></i>
						</div>
					</div>
					<section className="o-section o-section--waveup o-section__animation">
						<div className="o-section__container">
							<div className="o-section__wrapper">
							</div>
						</div>
					</section>
				</div>
				<p className="a-text">{t('CompanyOverview.MQT(VietNam)')}</p>
			</div>
			<div className="m-paragraph m-paragraph--right o-recruit-article-section__paragraph">
				<h2 className="a-heading a-heading--dropblue">{t('CompanyOverview.WHYWORKWITHUS')}</h2>
				<p className="a-text">{t('CompanyOverview.Acareer')}</p>
			</div>
		</div>
		<div className="o-recruit-article-section__only-pc">
			<section className="o-section o-section--wavedown o-section__animation">
				<div className="o-section__container">
					<div className="o-section__wrapper"></div>
				</div>
			</section>
			<div className="m-media--image">
				<div className='animation_song' style={{ transform: 'rotate(180deg)', marginTop: '-50px', position: 'absolute', width: '100%' }}>
					<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
						viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
						<defs>
							<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
						</defs>
						<g className="parallax">
							<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
							<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
							<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
							<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
						</g>
					</svg>
				</div>
				<div className="m-media__video-content">
					<picture>
						<img className="a-image" src="/assets/images/imageMQT/overlay.jpg" />
					</picture>
				</div>
				<div className='animation_song' style={{ marginTop: '-120px', position: 'absolute', width: '100%' }}>
					<svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
						viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
						<defs>
							<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
						</defs>
						<g className="parallax">
							<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
							<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
							<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
							<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
						</g>
					</svg>
				</div>
			</div>
			<section className="o-section o-section--wavedown o-section__animation">
				<div className="o-section__container">
					<div className="o-section__wrapper"></div>
				</div>
			</section>
		</div>
	</section>
}

export default CompanyOverview