import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import $ from "jquery";
const Navbar = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  const { t, i18n } = useTranslation();
  let changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    function dynamicCurrentMenuClass(selector) {
      let FileName = window.location.href.split("/").reverse()[0];

      selector.find("li").each(function() {
        let anchor = $(this).find("a");
        if ($(anchor).attr("href") === FileName) {
          $(this).addClass("current");
        }
      });
      // if any li has .current elmnt add class
      selector.children("li").each(function() {
        if ($(this).find(".current").length) {
          $(this).addClass("current");
        }
      });
      // if no file name return
      if ("" === FileName) {
        selector
          .find("li")
          .eq(0)
          .addClass("current");
      }
    }

    if ($(".main-nav__main-navigation").length) {
      let mobileNavContainer = $(".mobile-nav__container");
      let mainNavContent = $(".main-nav__main-navigation").html();
      mobileNavContainer.html(function() {
        return mainNavContent;
      });
      //Dropdown Button
      mobileNavContainer
        .find("li.dropdown .dropdown-btn")
        .on("click", function(e) {
          $(this).toggleClass("open");
          $(this)
            .parent()
            .parent()
            .children("ul")
            .slideToggle(500);
          e.preventDefault();
        });

      // dynamic current class
      let mainNavUL = $(".main-nav__main-navigation").find(
        ".main-nav__navigation-box"
      );
      let mobileNavUL = mobileNavContainer.find(".main-nav__navigation-box");

      dynamicCurrentMenuClass(mainNavUL);
      dynamicCurrentMenuClass(mobileNavUL);
    }

    $(window).on("scroll", function() {
      if ($(".orrri").length) {
        var headerScrollPos = 100;
        var stricky = $(".orrri");
        if ($(window).scrollTop() > headerScrollPos) {
          stricky.addClass("stricked-menu");
        } else if ($(this).scrollTop() <= headerScrollPos) {
          stricky.removeClass("stricked-menu");
        }
      }
    });
  });

  return (
    <header className="site-header-one go-top narbar-new mt-3">
      <nav className="main-nav__one stricky orrri">
        <div className="container-fluid orrri">
          <div className="main-nav__logo-box narbar_mobile">
            <Link to="/">
              <img
                src={publicUrl + "assets/images/web mqt.png"}
                alt={imgattr}
                height="70"
              />
            </Link>
            <a href="/" className="side-menu__toggler">
              <i
                className="fa fa-bars"
                style={{ color: "rgb(35, 139, 205)" }}
              />
            </a>
          </div>
          <div className="main-nav__main-navigation" style={{ margin: "auto" }}>
            <ul className=" main-nav__navigation-box">
              <li>
                <Link
                  to="/"
                  id="home"
                  style={{
                    fontSize: "23px",
                    fontFamily: "var(--LexendDecaSemiBold)"
                  }}
                >
                  {t("narbar.home")}
                </Link>
              </li>
              <li>
                <Link
                  to="/abouts"
                  id="abouts"
                  style={{
                    fontSize: "23px",
                    fontFamily: "var(--LexendDecaSemiBold)"
                  }}
                >
                  {t("narbar.aboutMQT")}
                </Link>
              </li>
              <li>
                <Link
                  to="/product"
                  id="product"
                  style={{
                    fontSize: "23px",
                    fontFamily: "var(--LexendDecaSemiBold)"
                  }}
                >
                  {t("narbar.product")}
                </Link>
              </li>
              {/* <li >
								<Link to="/recruitment" id="recruitment">{t('narbar.recruitment')}</Link>
							</li> */}
              {/* <li>
								<Link to="/contact" id="contact">{t('narbar.contact')}</Link>
							</li> */}
            </ul>
          </div>
          {/* <div className="main-nav__right main-nav__main-navigation" style={{ display: "block", position: 'relative', marginLeft: "-70px" }}>
						<ul className=" main-nav__navigation-box">
							<li className="dropdown" >
								{
									lang === "en" ? (
										<p className='stylep' style={{ marginTop: '13px' }}><img src={publicUrl + "assets/images/country/england.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />{t('narbar.language')}</p>
									) : (
										lang === "jp" ? (
											<p className='stylep' style={{ marginTop: '13px' }}><img src={publicUrl + "assets/images/country/japan.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />{t('narbar.language')}</p>
										) : (
											<p className='stylep' style={{ marginTop: '13px' }}><img src={publicUrl + "assets/images/country/vietnam.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />{t('narbar.language')}</p>
										)
									)
								}

								<ul className='stylelanguages'>
									<li><p className='stylep' onClick={() => changeLanguage('vi')}><img src={publicUrl + "assets/images/country/vietnam.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />VI</p></li>
									<li><p className='stylep' onClick={() => changeLanguage('en')}><img src={publicUrl + "assets/images/country/england.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />EN</p></li>
									<li><p className='stylep' onClick={() => changeLanguage('jp')}><img src={publicUrl + "assets/images/country/japan.svg"} alt={imgattr} height='20' style={{ marginRight: '10px' }} />JP</p></li>
								</ul>
							</li>
						</ul>
					</div> */}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
