import React, { Component } from 'react';

class Water extends Component {

	render() {

		return (
			// <div className="a-line">
			// 	<svg width="732px" height="4096px" viewBox="0 0 732 4096" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="a-line__svg">
			// 		<title>line-1px</title>
			// 		<defs>
			// 			<linearGradient x1="10.9638852%" y1="50.0002478%" x2="97.4261253%" y2="50.0002478%" id="linearGradient-1">
			// 				<stop stopColor="#FFFFFF" stopOpacity="0.2" offset="0.09065641%"></stop>
			// 				<stop stopColor="#93CAE9" stopOpacity="0.5" offset="21.4%"></stop>
			// 				<stop stopColor="#3CA0D7" stopOpacity="0.8" offset="51.46%"></stop>
			// 				<stop stopColor="#A0D1EC" stopOpacity="0.01" offset="80.31%"></stop>
			// 				<stop stopColor="#FFFFFF" stopOpacity="0" offset="100%">
			// 				</stop></linearGradient></defs>
			// 		<g id="UI-design_PC" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			// 			<g id="TOP_OGN" transform="translate(-242.000000, 0.000000)" fill="url(#linearGradient-1)">
			// 				<path d="M993.573099,4111 C973.492902,4090.9198 952.720284,4069.6526 932.442252,4045.12113 C884.764148,3989.33181 844.603754,3906.53809 817.203681,3814.05048 C782.681569,3698.81191 763.986213,3571.10977 754.984745,3444.49572 C745.88436,3317.68383 748.258373,3191.06978 755.182579,3065.14815 C769.129909,2813.4038 802.366097,2566.80315 817.500433,2307.5411 C821.951708,2231.47375 824.919225,2153.52697 825.413811,2073.8986 C826.106232,1957.07736 821.259288,1835.50808 806.223869,1714.63123 C802.168263,1680.30695 797.123484,1646.37834 791.287368,1613.14216 C776.449783,1528.37009 756.666338,1447.65363 734.508879,1373.66355 C703.646704,1269.99829 668.531088,1176.52151 634.107892,1086.9025 C599.58578,997.679163 566.250674,910.730919 533.014485,820.518407 C475.840327,665.416193 421.040183,508.335635 368.119466,356.299856 C325.782892,234.730582 284.435491,116.227743 242.494586,0 L242,3.65993744 C283.644153,119.19526 324.79372,236.906761 366.833542,357.684697 C424.799038,523.964557 474.653321,667.097786 531.926396,822.397834 C565.261501,912.808181 598.596607,999.954259 633.11872,1089.1776 C667.640833,1178.89552 702.756449,1272.17447 733.519706,1375.54297 C764.282964,1478.21906 790.594947,1593.95221 805.13578,1715.22474 C820.171198,1835.70592 824.919225,1956.87952 824.325722,2073.50294 C823.831136,2152.93347 820.863619,2230.68241 816.412344,2306.65084 C801.376925,2565.12156 768.140736,2811.42546 754.193407,3063.36764 C747.269201,3189.38819 744.79627,3316.29899 753.797738,3443.50654 C762.700289,3570.51627 781.296728,3698.71299 815.719923,3814.7429 C843.119995,3907.52726 883.082555,3991.70582 931.057411,4048.08864 C950.247353,4071.43311 969.832964,4091.81006 988.825072,4110.90108 L993.573099,4110.90108 L993.573099,4111 Z" id="line-1px"></path></g></g><image className="a-line__drop a-line__drop--show" width="32" height="32" href="https://organovietnam.vn/static/drop-a7f56da4e20cecfc0e712317b6ad2865.gif">
			// 		</image>
			// 	</svg>
			// </div>
			<></>
		)
	}
}

export default Water