import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as  HashRouter, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home/home-v1';
import AboutPage from './components/about/aboutpage';
import './i18n';
import Product from './components/product/product';
import Recruitment from './components/recruitment/recruitment';
import ContactPage from './components/contactus/contact';
import NotFound from './components/notfound/notfound';

class Root extends Component {
	render() {
		return (
			<React.StrictMode>
					<HashRouter>
							<Switch>
								<Route exact path="/" component={HomeV1} />
								<Route path="/abouts" component={AboutPage} />
								<Route path="/product" component={Product} />
								<Route path="/recruitment" component={Recruitment} />
								<Route path="/contact" component={ContactPage} />
								<Route path='*' exact={true} component={NotFound} />
							</Switch>
					</HashRouter>
			</React.StrictMode>
		)
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('root'));
