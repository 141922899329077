import React from 'react';
import Navbar from '../global-components/navbar';
import Footer from '../global-components/footer';
import { useTranslation } from 'react-i18next';
import Body from './body';
import Page_header from '../global-components/page-header';

const NotFound = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        
        <Body />
        <Footer />
    </div>
}

export default NotFound

