import React from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import ContactInfomation from './contactInfomation';
import Footer from '../global-components/footer';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
    const { t } = useTranslation();
    return <div >
        <Navbar />
        <PageHeader headertitle ={t('home.contactus.title')} />
        <ContactInfomation />
        <Footer />
        </div>
}

export default ContactPage