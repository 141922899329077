import React from 'react';
import { useTranslation } from 'react-i18next';

const Mainsaleitems = () => {
	const { t } = useTranslation();

	return <section className="o-section o-section--waveup p-home__main-sale o-section__animation mt-5 reveal">
		<div className="o-section__container">
			{/* <div className="o-section__wrapper"> */}
				<div className="o-section__container home_animation">
					<div className="o-section__wrappered">
						<div className="col m-paragraph">
							<h2 className="a-heading a-heading--dropblue mb-3">{t('home.mainsaleitems.title')}</h2>
							<div className="itemifoacti">
								<div className="num">1</div>
								<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src='/assets/images/icon_4.png' /></a></div>
								<div className="txt">{t('home.mainsaleitems.info1')}</div>
							</div>
							<div className="itemifoacti">
								<div className="num">2</div>
								<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src='/assets/images/icon_4.png' /></a></div>
								<div className="txt">{t('home.mainsaleitems.info2')}</div>
							</div>
							<div className="itemifoacti">
								<div className="num">3</div>
								<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src='/assets/images/icon_4.png' /></a></div>
								<div className="txt">{t('home.mainsaleitems.info3')}</div>
							</div>
							<div className="itemifoacti">
								<div className="num">4</div>
								<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src='/assets/images/icon_4.png' /></a></div>
								<div className="txt">{t('home.mainsaleitems.info6')}</div>
							</div>
							<div className="itemifoacti">
								<div className="num">5</div>
								<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src='/assets/images/icon_4.png' /></a></div>
								<div className="txt">{t('home.mainsaleitems.info7')}</div>
							</div>
						</div>
						<div className="p-home__profile-image col" style={{alignSelf: 'center'}}>
							<img className="a-images logomainsale image-home" src='/assets/images/ro/2.jpg' alt="main sale items" />
						</div>

					</div>
				</div>
				{/* <div className="m-paragraph--has-image" style={{ flex: '1 0' }}>
					<h2 className="a-heading a-heading--dropblue">{t('home.mainsaleitems.title')}</h2>
					<img className="a-images logomainsale image-home" src='/assets/images/ro/2.jpg' alt="main sale items" />
					<p className="a-text p-home__main-sale-items">{t('home.mainsaleitems.info')}
					</p>

					<div className="itemifoacti">
						<div className="num">1</div>
						<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src="https://moitruongachau.com/vnt_upload/field/10_2021/icon_4.png" /></a></div>
						<div className="txt">{t('home.mainsaleitems.info1')}</div>
					</div>
					<div className="itemifoacti">
						<div className="num">2</div>
						<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src="https://moitruongachau.com/vnt_upload/field/10_2021/icon_4.png" /></a></div>
						<div className="txt">{t('home.mainsaleitems.info2')}</div>
					</div>
					<div className="itemifoacti">
						<div className="num">3</div>
						<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src="https://moitruongachau.com/vnt_upload/field/10_2021/icon_4.png" /></a></div>
						<div className="txt">{t('home.mainsaleitems.info3')}</div>
					</div>
					<div className="itemifoacti">
						<div className="num">4</div>
						<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src="https://moitruongachau.com/vnt_upload/field/10_2021/icon_4.png" /></a></div>
						<div className="txt">{t('home.mainsaleitems.info6')}</div>
					</div>
					<div className="itemifoacti">
						<div className="num">5</div>
						<div className="icon"><a rel="nofollow"><img data-ck-zoom="yes" src="https://moitruongachau.com/vnt_upload/field/10_2021/icon_4.png" /></a></div>
						<div className="txt">{t('home.mainsaleitems.info7')}</div>
					</div>
					<a className="a-view-more" href="/product">{t('home.mainsaleitems.viewmore')}<i className="a-icon--view-more a-view-more__icon"></i></a>
				</div> */}
			</div>
		{/* </div> */}
	</section>
}

export default Mainsaleitems