import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useState } from 'react';
import validator from 'validator'
import { error } from 'jquery';
const Contacus = () => {
	const { t } = useTranslation();
	const [name, setName] = useState(null)
	const [phone, setPhone] = useState(null)
	const [message, setMessage] = useState(null)
	const [phoneError, setPhoneError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [email, setMail] = useState(null)
	const validatePhone = (e) => {
		var email = e.target.value
		if (validator.isMobilePhone(email)) {
			setPhone(email)
			setPhoneError(false)
		} else {
			setPhoneError(true)
		}
	}
	const validateEmail = (e) => {
		var email = e.target.value
		if (validator.isEmail(email)) {
			setMail(email)
			setEmailError(false)
		} else {
			setEmailError(true)
		}
	}
	const data = {
		"name": name,
		"email": email,
		"message": message,
		"phone": phone
	}
	const sendMail = () => {
		axios({
			method: 'post',
			url: 'https://rxyypq6o1i.execute-api.us-east-1.amazonaws.com/dev/sendMail',
			data: data
		}).finally(
			alert("Send Feedback Success"),
			setTimeout(() => {
				window.location.reload()
			}, 1000)
		)
	}
	return <section className="o-section o-section__animation mt-5 reveal" style={{ paddingTop: '60px' }}>
		<div style={{ width: '60%', margin: 'auto' }}>
			<div style={{ display: 'grid' }}>
				<h2 className='a-heading mb-3' style={{ textTransform: 'uppercase' }}>{t('Form.feedback')}</h2>
				<input type="text" required className='form-control mt-3 styleinput' placeholder={t('Form.name')} onChange={event => setName(event.target.value)} />
				<input type="text" required className='form-control mt-3 styleinput' placeholder={t('Form.phone')} onChange={(e) => validatePhone(e)} />
				{phoneError && <span style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}>{t('Form.phoneError')}</span>}
				<input type="email" required className='form-control mt-3 styleinput' placeholder='Email[*]' onChange={(event) => validateEmail(event)} />
				{emailError && <span style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}>{t('Form.emailError')}</span>}
				<textarea name="" required className='form-control mt-3 styleinput' id="" cols="10" rows="10" placeholder={t('Form.message')} style={{ height: '50px' }} onChange={event => setMessage(event.target.value)}></textarea>
				{(!name || !message || !phone || !email) && <span style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}>{t('Form.error')}</span>}
				<button disabled={!name || !message || !phone || !email} className='form-control mt-3 styleinput  btnsend' onClick={sendMail}>{t('Form.btnsend')}</button>
			</div>
		</div>
	</section>
}

export default Contacus