import React, { Component } from "react";

class Page_header extends Component {
  render() {
    let HeaderTitle = this.props.headertitle;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;
    return (
      <section
        className="page-header"
        style={{
          // backgroundImage:
          //   "url(" + publicUrl + "assets/images/backgrounds/aboutbg.png)",
          background: 'linear-gradient(180deg,rgb(0 142 245 / 40%),rgba(165, 166, 246, 0))'
        }}
      >
        <div className="container" style={{ textAlign: "left" }}></div>
        {/* /.container */}
      </section>
    );
  }
}

export default Page_header;
