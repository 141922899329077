import React, { Component } from "react";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/theme.js";
    document.body.appendChild(minscript);
    $(".go-top")
      .find("a")
      .on("click", function() {
        $(window).scrollTop(0);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <div>
        <footer
          className="site-footer-one o-footer"
          style={{
            background:
              "linear-gradient(rgba(165, 166, 246, 0), rgba(0, 142, 245, 0.4))"
          }}
        >
          {/* <video src="/video/footer-bg.mp4" autoPlay loop muted></video> */}
          <div className="site-footer-one__upper">
            <div className="container">
              <div className="row dblock">
                <div className="col">
                  <div className="footer-widget footer-widget__about">
                    <div style={{ textAlignLast: "center" }}>
                      <a href="index-2.html">
                        <img
                          src={publicUrl + "assets/images/web mqt.png"}
                          alt={imgattr}
                          width="200"
                        />
                      </a>
                    </div>
                    <div style={{ textAlignLast: "center" }}>
                      <iframe
                        className="a-map__iframe"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31341.84500619742!2d106.70596174962132!3d10.908064943150363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d829df7f914f%3A0xd1a1920c5b4d96fd!2zQsOsbmggSMOyYSwgVGh14bqtbiBBbiwgQsOsbmggRMawxqFuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1663137349650!5m2!1svi!2s"
                        width="80%"
                        height="180px"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        style={{ border: "0px", marginTop: "20px" }}
                      ></iframe>
                    </div>
                  </div>
                  {/* /.footer-widget */}
                </div>
                {/* /.col-lg-3 */}
                <div className="col">
                  <div className="footer-widget footer-widget__contact">
                    <h3 className="footer-widget__title">Trụ sở</h3>
                    <div className="footer-widget__contact-box">
                      <div className="footer-widget__contact-top">
                        <i className="far fa-map-marked" />
                        <h3>Địa chỉ</h3>
                      </div>
                      <p>
                        383 KP Đông Ba - P. Bình Hòa - TP Thuận An - T Bình
                        Dương
                      </p>
                    </div>
                    <div className="footer-widget__contact-box">
                      <div className="footer-widget__contact-top">
                        <i className="far fa-phone" />
                        <h3>Số ĐT</h3>
                      </div>
                      <p>
                        <a href="tel:0977870664">
                          Mr. Cường&ensp;(Giám Đốc): 0977.870.664
                        </a>
                        <br />
                        <a href="tel:0902408665">
                          Mr. Vương&ensp;(P. Giám Đốc): 0902.408.665
                        </a>
                      </p>
                    </div>
                    <h3 className="footer-widget__title"></h3>
                    {/* /.footer-widget__title */}
                    <div className="footer-widget__contact-box">
                      <div className="footer-widget__contact-top">
                        <i className="far fa-envelope" />
                        <h3>EMail</h3>
                      </div>
                      <p>
                        <a href="mailto:contact@mqttech.com.vn">
                          contact@mqttech.com.vn
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* /.site-footer-one__upper */}
          <div className="site-footer-one__bottom">
            <div className="container" style={{ position: "relative" }}>
              <div className="site-footer-one__bottom-line" />
              {/* /.site-footer-one__bottom-line */}
              <p style={{ textAlign: "center" }}>
                Copyright@2022 MQT COMPANY LIMITED. All Right Reserved.{" "}
              </p>
            </div>
            {/* /.container */}
          </div>
          {/* /.site-footer-one__bottom */}
        </footer>
      </div>
    );
  }
}

export default Footer_v1;
